export default {
  title: 'Connections',
  newConnection: 'New connection',
  createNewConnection: 'Create a new connection',
  createYourFirstConnection: 'Create your first connection',
  connectionsListBanner: {
    title: 'Connect your Data Warehouse',
    content:
      'Select your provider, enter the connection details, and start using the CARTO platform with your data warehouse.',
    providers: {
      googleBigQuery: 'Google Big Query',
      snowflake: 'Snowflake',
      postgresql: 'PostgreSQL',
      amazonRedshift: 'Amazon Redshift',
      databricks: 'Databricks',
      databricksRest: 'Databricks',
      andMore: 'And more to come...'
    }
  },
  cards: {
    spatialExtension: {
      title: 'Spatial Extension',
      enabled: 'Enabled',
      disabled: 'Disabled',
      notAvailable: 'Coming soon',
      activation: 'Spatial extension activation',
      notAvailableDescription: 'This feature will be available soon.',
      availableDescription:
        'By activating this option the {spatialExtensionLink} and your {dataObservatoryLink} subscriptions will be available from this connection.',
      analyticsToolbox: 'CARTO Analytics Toolbox',
      available: 'Available',
      updateAvailable: 'Update available',
      unavailable: 'Unavailable',
      installNow: 'Install now',
      analyticsToolboxAvailable: 'Learn more about Available status (New tab)',
      analyticsToolboxUnavailable: 'Learn more about Unavailable status (New tab)',
      updateStatus: 'Refresh status',
      learnMore: 'Learn more',
      viewDoc: 'Visit documentation',
      contactAdminTooltip:
        'Contact the Admin user of your CARTO organization to {action} the Analytics Toolbox for {provider}',
      requiresManualInstallation: 'Requires manual installation'
    },
    cartoDW: {
      storage: 'Storage',
      unlimited: 'Unlimited',
      computing: 'Computing',
      computingValue: '{size}TB / day',
      features: 'Features',
      banner: {
        pretitle: 'Connection',
        title: 'CARTO Data Warehouse',
        info: 'Every CARTO organization includes a <b>scalable cloud Data Warehouse</b> by default, and in the same region.',
        infoNew:
          'Every CARTO organization includes a scalable cloud Data Warehouse by default, and in the same region.',
        infoLink: 'Learn more',
        connectionTitle: 'Connection name'
      },
      limits: {
        storage: 'Storage',
        computing: 'Computing',
        maps: 'Maps created',
        storageSize: '10GB',
        acceleration: 'Acceleration',
        accelerationSize: '1GB'
      },
      consoleAccess: {
        title: 'Console access',
        getAccessText:
          'Get access to the CARTO Data Warehouse console to add, manage, rename or update your tables and tilesets.',
        getAccess: 'Get access',
        accessConsole: 'Access console',
        docLink: 'How to use this feature',
        getAcccessSucces: 'Successfully added CARTO Data Warehouse grant.',
        getAcccessError: 'Failed to add CARTO Data Warehouse grant.',

        getAccessDialog: {
          title: 'Get console access',
          message:
            'The CARTO Data Warehouse is powered by Google Cloud Platform. Please, tell us which Google account do you want to use to access the console.',
          emailLabel: 'Google Account',
          invalidEmail: `Make sure it's a valid email`,
          invalidAccountNotGoogle: `It seems that this email is not associated with a Google account. Please review and try again (no email aliases allowed).`,
          successMessage: 'Successfully added CARTO Data Warehouse grant.'
        },
        revokeAccessDialog: {
          title: 'Remove console access',
          message: 'You can restore access with another Google account at any time.',
          successMessage: 'Successfully removed CARTO Data Warehouse grant.',
          errorMessage: 'Failed to remove CARTO Data Warehouse grant.'
        }
      }
    },
    tooltips: {
      maps: 'Maps created'
    },
    editDialog: {
      title: 'Save changes',
      description: 'Are you sure that you want to make changes to the {provider} connection {name}?',
      errorMessage: "We couldn't save the changes, please try again",
      edit: 'Yes, save',
      warning: {
        title: 'Making changes in this connection could affect:'
      }
    },
    viewDialog: {
      title: 'Connection details'
    },
    deleteDialog: {
      title: 'Delete connection',
      description: 'Are you sure you want to delete the {provider} connection {name}?',
      errorMessage: "We couldn't delete the connection, please try again",
      error409Message:
        "This connection can't be deleted because it's still used in maps created by other users in your organization. Please contact other map owners so they remove this connection from their maps.",
      cantDeleteMessage:
        "This connection can't be deleted because it's being shared with other users in your organization.",
      delete: 'Yes, delete',
      warning: {
        title: 'Deleting this connection will also delete:',
        message:
          '{maps, plural, =0 {} =1 {# map} other {# maps}} {showAnd, plural, =0 {} other {and}} {workflows, plural, =0 {} =1 {# workflow / scheduled workflow} other {# workflows / scheduled workflows}}'
      }
    }
  },
  create: {
    common: {
      title: 'Connect your data',
      titleEdit: 'Edit connection',
      warehouse: 'Data warehouse',
      selectWarehouse: 'Select data warehouse',
      success: 'The connection was successful!',
      connect: 'Connect',
      edit: 'Save changes',
      tryAgain: 'Try again',
      configureCredentials: 'Setup connection',
      usingUsernamePassword: 'Connect using username/password',
      or: 'or',
      empty: 'Select a data warehouse above to configure a connection',
      canNotCreate: `Your current role doesn't allow creating connections. Talk to your organization admin.`,
      connectionName: {
        error:
          "A connection name should be 3-50 characters long, containing only lowercase letters and numbers. Dashes and underscores are allowed if they're not leading or tailing."
      },
      requestConnector: 'Looking for another data warehouse?',
      requestFabricConnector: 'Fabric',
      requestConnectorAsk: 'Contact us',
      beta: 'Beta',
      sharingOptions: 'Sharing options',
      permissionsAndSharing: 'Permissions and sharing',
      change: 'Change',
      manageOption: 'Manage option',
      alwaysPrivateForOauth: "Connection using OAuth (linked to personal accounts) can't be shared"
    },
    databricks: {
      infoTitle: 'Connect to your Databricks database',
      infoDescription:
        'CARTO can connect to a Databricks SQL Warehouse or a Databricks cluster. In both cases, the connection fully supports H3 indexes',
      requestExplicitEnablement:
        'Databricks currently lacks official generic Spatial SQL support. We’re actively working to establish this. Meanwhile, we’re evaluating and exploring options to enable vector, raster and H3 support. Please {letUsKnow} if you need this connection enabled.',
      documentation: 'Databricks documentation',
      back: 'Back to Databricks info',
      form: {
        connectionNameLabel: 'Name',
        connectionNamePlaceholder: 'Type a name for your connection',
        serverLabel: 'Server',
        serverPlaceholder: 'Type your server DNS name',
        databaseLabel: 'Catalog',
        databasePlaceholder: 'hive_metastore',
        httpPathLabel: 'HTTPPath',
        httpPathPlaceholder: 'Type your ODBC HTTPPath',
        passwordLabel: 'Token',
        passwordPlaceholder: 'Type your Databricks personal token',
        portLabel: 'Port',
        portPlaceholder: 'Type your port',
        warningAlert: {
          title: 'Connecting to Databricks',
          content: {
            1: 'Connections are only supported in Photon-enabled clusters (with Databricks Runtime 11.2 or above) and Databricks SQL endpoints at the DBSQL Pro/Serverless tiers.',
            2: 'Working with geometries is only supported when connecting to a Databricks cluster and it requires the installation of the com.carto.analyticstoolbox.core library (in Beta). Check out <a>the instructions</a> in our documentation.'
          },
          checkboxLabel: 'Got it!'
        }
      }
    },
    databricksRest: {
      infoTitle: 'Connect to your Databricks database',
      infoDescription:
        'CARTO can connect to a Databricks SQL Warehouse to push down SQL queries and to a Databricks cluster to create and run jobs.',
      requestExplicitEnablement:
        'Databricks currently lacks official generic Spatial SQL support. We’re actively working to establish this. Meanwhile, we’re evaluating and exploring options to enable vector, raster and H3 support. Please {letUsKnow} if you need this connection enabled.',
      documentation: 'Databricks documentation',
      back: 'Back to Databricks info',
      form: {
        connectionNameLabel: 'Name',
        connectionNamePlaceholder: 'Type a name for your connection',
        hostLabel: 'Instance Name',
        hostPlaceholder: 'E.g.: carto-data-science.cloud.databricks.com',
        catalogLabel: 'Catalog',
        catalogPlaceholder: {
          waitingForCredentials: 'Enter workspace name and token to get the available catalogs',
          loading: '... getting the available catalogs',
          error: 'Authorization failed: check the workspace name and token to get the available catalogs',
          empty: 'No suitable catalogs available for entered credentials',
          available: 'Select a Catalog'
        },
        tokenLabel: 'Token',
        tokenPlaceholder: 'Type your Databricks personal token',
        clusterLabel: 'All-purpose compute',
        clusterPlaceholder: {
          waitingForCredentials: 'Enter workspace name and token to get the available compute',
          loading: '... getting the available compute',
          error: 'Authorization failed: check the workspace name and token to get the available compute',
          empty: 'No suitable clusters available for entered credentials',
          available: 'Select an All-purpose compute'
        },
        warehouseLabel: 'SQL Warehouse',
        warehousePlaceholder: {
          waitingForCredentials: 'Enter workspace name and token to get the available SQL Warehouses',
          loading: '... getting the available SQL Warehouses',
          error: 'Authorization failed: check the workspace name and token to get the available SQL warehouses',
          empty: 'No suitable SQL warehouses available for entered credentials',
          available: 'Select a SQL warehouse'
        },
        warningAlert: {
          title: 'Connecting to Databricks',
          content: {
            1: 'Connections are only supported in Photon-enabled clusters (with Databricks Runtime 11.2 or above) and Databricks SQL endpoints at the DBSQL Pro/Serverless tiers.',
            2: 'Working with geometries is only supported when connecting to a Databricks cluster and it requires the installation of the com.carto.analyticstoolbox.core library (in Beta). Check out <a>the instructions</a> in our documentation.'
          },
          checkboxLabel: 'Got it!'
        }
      }
    },
    postgres: {
      infoTitle: 'Connect to your PostgreSQL database',
      infoDescription:
        'Use CARTO with your data in a PostgreSQL-compatible database, including Google Cloud SQL, Amazon Aurora & RDS and Azure Database for PostgreSQL',
      documentation: 'PostgresSQL documentation',
      back: 'Back to PostgreSQL info',
      form: {
        connectionNameLabel: 'Name',
        connectionNamePlaceholder: 'Type a name for your connection',
        serverLabel: 'Server',
        serverPlaceholder: 'Type your server DNS name or IP address',
        databaseLabel: 'Database',
        databasePlaceholder: 'Type your database name',
        usernameLabel: 'Username',
        usernamePlaceholder: 'Type your username',
        passwordLabel: 'Password',
        passwordPlaceholder: 'Type your password',
        portLabel: 'Port',
        portPlaceholder: 'Type your port'
      }
    },
    redshift: {
      infoTitle: 'Connect to your Redshift cluster',
      infoDescription: 'Use CARTO with your data in an Amazon Redshift data warehouse',
      documentation: 'Redshift documentation',
      back: 'Back to Redshift info',
      form: {
        connectionNameLabel: 'Name',
        connectionNamePlaceholder: 'Type a name for your connection',
        clusterLabel: 'Cluster',
        clusterPlaceholder: 'Type your cluster name',
        databaseLabel: 'Database',
        databasePlaceholder: 'Type your database name',
        usernameLabel: 'Username',
        usernamePlaceholder: 'Type your username',
        passwordLabel: 'Password',
        passwordPlaceholder: 'Type your password',
        portLabel: 'Port',
        portPlaceholder: 'Type your port'
      }
    },
    snowflake: {
      infoTitle: 'Connect to your Snowflake account',
      infoDescription: 'Use CARTO with your data in a Snowflake data warehouse',
      documentation: 'Snowflake documentation',
      back: 'Back to Snowflake info',
      lookingOAuth: 'Looking to connect using OAuth?',
      oAuthAdminDescription:
        'To avoid using usernames and passwords, CARTO can also connect to Snowflake via OAuth and External OAuth. Set up an integration and enable that connection method for all users.',
      oAuthEditorDescription:
        'To avoid using usernames and passwords, CARTO can also connect to Snowflake via OAuth and External OAuth. Please contact your CARTO admin so they set it up.',
      goToIntegrations: 'Go to Integrations',
      setupWithOAuth: 'Setup connection with OAuth',
      connectUsingOAuth: 'Connect using OAuth',
      signInToSnowflake: 'Sign in to Snowflake',
      roleDialog: {
        title: 'What role should CARTO use?',
        description:
          'Before connecting via your Snowflake credentials, please review and modify (if needed) the role that CARTO will use.'
      },
      form: {
        connectionNameLabel: 'Name',
        connectionNamePlaceholder: 'sfconn',
        accountLabel: 'Account name',
        accountPlaceholder: 'abc12345.us-east-1',
        databaseLabel: 'Database',
        databasePlaceholder: 'DEMO_DB',
        usernameLabel: 'Username',
        usernamePlaceholder: 'MYUSERNAME',
        passwordLabel: 'Password',
        passwordPlaceholder: '••••••••••••••',
        warehouseLabel: 'Warehouse',
        warehousePlaceholder: 'COMPUTE_WH',
        roleLabel: 'Role',
        rolePlaceholder: 'PUBLIC',
        connectedSnowflakeUser: 'Connected Snowflake user'
      },
      oauth: {
        error: 'Something went wrong. Please complete the OAuth flow again to make this connection available.'
      }
    },
    bigquery: {
      infoTitle: 'Connect to your BigQuery projects',
      googleMsg:
        'CARTO’s use and transfer to any other app of information received from Google APIs will adhere to {googleLink}, including the Limited Use requirements.',
      googleLink: 'Google API Services User Data Policy',
      infoDescription:
        'CARTO seamlessly connects to BigQuery to enable geospatial analytics and high-performance data visualizations.',
      infoLink: 'We call this the {linkText}',
      infoLinkText: 'CARTO Analytics Toolbox for BigQuery.',
      documentation: 'BigQuery documentation',
      configureWithWIF: 'With Workload identity federation',
      configureCredentials: 'With a service account',
      connectUsingGoogle: 'Connecting with Google',
      continueWithGoogle: 'Continue with Google',
      back: 'Back to BigQuery info',
      next: 'Next',
      oauth: {
        googleAccount: 'Connected Google account',
        error: 'Something went wrong. Please sign up again with your Google account to make this connection available.'
      },
      workloadIdentity: {
        emailAliasInCard: 'Workload Identity',
        info: {
          message:
            'CARTO and BigQuery can also be connected via Workload Identity Federation. This allows your BigQuery administrators to grant granular permissions to users in CARTO without having to upload and manage service accounts.',
          messageEditor:
            'CARTO and BigQuery can also be connected via Workload Identity Federation. This allows your BigQuery administrators to grant granular permissions to users in CARTO without having to upload and manage service accounts. Please contact your CARTO admin so they set it up.'
        }
      },
      check: {
        description: 'CARTO will connect to your data in BigQuery with the authentication method selected above.',
        parameter: ''
      },
      googleApiServices: {
        description:
          "CARTO's use and transfer to any other app of information received from Google APIs will adhere to <link>Google API Services User Data Policy</link>, including the Limited Use requirements."
      },
      help: {
        description:
          'For more detailed information on CARTO usage limits and BigQuery, <link>check out our documentation area.</link>'
      },
      info: {
        1: {
          description: 'The GCP account used for the connection should be granted a set of predefined BigQuery roles.',
          parameter: 'BigQuery roles'
        },
        2: {
          description:
            'BigQuery User and BigQuery Data Editor are needed to list datasets and tables in your projects, run queries and create maps from your data'
        },
        3: {
          description:
            'Our recommendation is to configure specific limits in BigQuery to avoid any unexpected processing charges.'
        }
      },
      terms: {
        text: {
          1: 'CARTO makes use of a set of custom functions available in BigQuery. We call this the {cse}.',
          2: 'Please ensure that the email address you use for your CARTO and Google BigQuery accounts are the same.',
          3: 'We will use your {googleEmail} to grant access to the Spatial Extension from the BigQuery Console. We will also use a {googleSA} for other operations such as listing the tilesets stored in your project’s datasets, publishing and unpublishing tilesets for public visualizations.',
          4: 'Our recommendation is to configure specific limits in BigQuery to avoid any unexpected processing charges. For more detailed information on CARTO usage limits and BigQuery, check out our {docs} area.',
          cse: 'Carto Spatial Extension for BigQuery',
          googleEmail: 'Google Account Email',
          googleSA: 'Google Cloud service account',
          docs: 'documentation'
        }
      },
      form: {
        connectionNameLabel: 'Name',
        connectionNamePlaceholder: 'Type a name for your connection',
        clientEmailLabel: 'Email',
        audienceLabel: 'Audience',
        billingProjectIdLabel: 'Billing Project ID'
      },
      serviceAccount: {
        title: 'Service account key',
        placeholder: 'Upload your JSON file',
        error: 'The provided service account is not valid',
        permissionsError:
          'There seems to be an issue with the service account provided. It might be missing the required permissions. Please check the documentation to ensure that you have provided them correctly.',

        reUpload: 'Upload a different service account',
        help: {
          text: 'Upload a service account key in JSON format.',
          expand: 'More info',
          collapse: 'Collapse'
        },
        text: {
          1: 'Upload a service account key in {json} format. Get help creating and managing Google Cloud service accounts {here}.',
          2: 'We will use this service account to list tables and datasets in your projects, run queries and create maps from your data. Be sure that the service account has {bqDataEditor} and {bqJobUser} predefined roles.',
          3: 'Read our {docs} if you want to learn about the specific permissions that CARTO requires.',
          json: 'JSON',
          here: 'here',
          bqDataEditor: 'BigQuery Data Editor',
          bqJobUser: 'BigQuery Job User',
          docs: 'documentation'
        }
      },
      iamPrincipal: {
        label: 'IAM Principal',
        helperText: 'Use this IAM Principal to manage permissions to this connection in GCP.'
      },
      serviceAccountEmail: {
        label: 'Service Account email for impersonation',
        placeholder: 'Type the email of the Service Account you want to impersonate',
        helperText:
          'Enter a Service Account email if you want to impersonate it when using this connection. Otherwise, leave it empty.'
      },
      billingProject: {
        title: 'Billing project',
        placeholder: 'Type to filter or select a billing project',
        help: 'Select a billing project where CARTO can run queries using your service account.',
        help_oauth: 'Select a billing project where CARTO can run queries using your credentials.'
      }
    },
    title: 'Connect to {provider}',
    subtitle: 'Your {provider} credentials',
    cancel: 'Cancel',
    connect: 'Connect',
    sslLabel: 'Use SSL to encrypt the connection',
    passwordPlaceholderEdit: '••••••••••••••',
    helpTitle: 'Need help?',
    helpDescription: `Please check our documentation if you are having trouble connecting to your data`,
    analyticsToolboxBanner: {
      title: 'CARTO Analytics Toolbox for {provider}',
      analyticsToolboxLink: 'Analytics Toolbox',
      guideLink: 'this guide',
      docLink: 'Check our documentation',
      documentationLink: 'documentation',
      content: {
        snowflake:
          'The {analyticsToolboxLink} provides geospatial functions to perform fundamental spatial operations, such as clustering and data enrichment, natively within the Snowflake environment.',
        bigquery:
          "The {analyticsToolboxLink} provides geospatial functions to perform fundamental spatial operations, such as clustering and data enrichment, natively within the BigQuery environment. Once you create a connection, your Service Account or Google user account will be granted permission to use all of the Analytics Toolbox's functions and procedures.Learn more in our {guideLink}.",
        redshift:
          'The {analyticsToolboxLink} provides geospatial functions to perform fundamental spatial operations, such as clustering and spatial indexing, natively within the Redshift environment. To install it, please follow the steps detailed in {guideLink}.',
        postgres:
          'The Analytics Toolbox provides additional geospatial functions to expand the possibilities of PostgreSQL + PostGIS, such as tileset generation functions or extensive support for quadbin as spatial index. Learn more in our {guideLink}.'
      },
      subContent: {
        redshift:
          'After installation, please ensure the user or role of your {provider} connection in CARTO has enough permissions to access it.'
      },
      installAvailable: {
        enabled:
          'The Analytics Toolbox is available as a Native App in the Snowflake Marketplace. Make sure to configure your Analytics Toolbox location in the Advanced options.',
        disabled:
          'Please contact your Snowflake administrator to install the Analytics Toolbox in your Snowflake account.'
      }
    },
    advanced: {
      title: 'Advanced options',
      analyticsToolboxDataLocationTitle: 'Analytics Toolbox & data location',
      atLocation: {
        title: 'Analytics Toolbox location',
        description:
          'Indicate the location of the <a>Analytics Toolbox for {provider}</a>. This setting will be observed by Builder, Workflows and Data Explorer to generate SQL queries that use Analytics Toolbox functions.'
      },
      doLocation: {
        title: 'Data Observatory location',
        description:
          'Indicate the location of your custom <a>Data Observatory subscriptions</a>. This setting will be observed by Data Explorer, Workflows and Enrichment to access your data subscriptions.'
      },
      workflows: {
        title: 'Workflows temp. location',
        descriptionBigQuery:
          'CARTO Workflows requires to save <a>temporary tables</a> in your data warehouse. In case you want to specify the location in where to store such tables (instead of the default <code>workflows_temp</code> dataset), please detail here the <code>dataset-id</code> within the selected BigQuery project or a different but valid <code>project-id.dataset-id</code>. If the dataset does not exist yet, it will get created.',
        descriptionGeneric:
          'CARTO Workflows requires to save <a>temporary tables</a> in your data warehouse. In case you want to specify the location in where to store such tables (instead of the default <code>workflows_temp</code> schema), please detail here the <code>schema</code> within the selected database or a different but valid <code>database.schema</code>. If the schema does not exist yet, it will get created.'
      },
      queryConcurrencyControl: {
        optionsTitle: 'Query management options',
        helperText:
          'These settings control concurrency and execution time of queries executed by CARTO. Set limits on the number of simultaneous queries and specify a maximum execution duration to optimize performance and resource usage on your data warehouse.',
        maxConcurrentQueries: {
          label: 'Max number of concurrent queries',
          placeHolder: '{default}',
          invalidMessage: 'It must be an integer between {min} and {max}'
        },
        queryTimeoutS: {
          label: 'Max query timeout',
          invalidMessage: 'It must be an integer between {min} and {max}',
          units: 'sec'
        }
      },
      dataTransferVersionInfo: {
        title: 'Data Transfer Version info',
        description:
          'CARTO Scheduling API in BigQuery OAuth connections requires to <a>generate a version info code</a>. This operation is required only once per connection.'
      }
    }
  },
  shareDialog: {
    confirm: 'Confirm',
    unsharingTitle: 'Do you want to change this connection to private?',
    sharingNotice: "By sharing this connection you're also sharing the permissions it might have.",
    unsharingWarning:
      'Turning this shared connection to private mode will break the data sources added by other users, affecting their maps.',
    requiresViewerCredential: {
      switchLabel: "Requires viewer's credentials",
      hint: 'Anyone using this connection will need to enter their own connection credentials, except in public maps. <docLink>More info</docLink>',
      alertInfo: 'This connection will require viewers to provide their own credentials.',
      disablingViewerCredentialsTitle: "Do you want to disable viewer's credentials?",
      disablingViewerCredentials:
        "Disabling viewer's credentials will break the data sources added by other users, affecting their maps."
    },
    shareWithGroups: {
      notAvailable:
        "This connection is shared with all users in your organization. If you're interested in sharing with certain users or groups, <link>let us know</link>."
    },
    viewerCredentials: {
      disabledBigquery: 'BigQuery OAuth connections can only be shared by requesting the credentials of each user',
      disabledBigqueryWif:
        'Workload Identity Federation connections can only be shared by requesting the credentials of each user.',
      disabledServiceAccountSnowflake:
        'Snowflake connections using a Service Account are not compatible with requiring viewer credentials.',
      disabledSnowflake: 'Snowflake OAuth connections can only be shared by requesting the credentials of each user'
    }
  },
  dataExplorer: {
    missingCredentials:
      "You don't have credentials to access this connection. Please add your credentials to access the data.",
    enterCredentials: 'Enter credentials'
  },
  credentialsDialog: {
    title: 'Credentials needed',
    promptConnection: 'This connection requires you to provide credentials to access the data:',
    promptEntity: 'Some data sources of this {entity} require you to provide credentials to access the data:',
    databricks: {
      tokenLabel: 'Your Databricks Personal Access Token',
      placeholder: 'Copy and paste your Databricks Personal Access Token',
      helper: 'Learn how to get your Databricks Personal Access Token <link>here</link>.'
    },
    delete: {
      title: 'Remove my credentials',
      question: 'Are you sure you want to remove your credentials from Connection {connectionName}?',
      info: 'You can always add your credentials again when opening maps or workflows that use this connection.',
      questionMultiple: 'Are you sure you want to remove your credentials from the connections used in this map?',
      error: 'Error deleting your credentials for the connection {connectionName}:',
      actionButton: 'Yes, remove'
    },
    addCredentials: 'Add my credentials',
    accessDeniedError: `We couldn't authenticate using this token, please review and try again.`
  }
}
